<template>
  <article :class="props.classes">
    <div
      class="flex items-center border-white rounded-sm cursor-pointer py-2xs px-xs border-t-1 border-r-1 border-l-1"
      :class="getActiveBarClasses()"
      @click="toggleVisibility()"
    >
      <slot name="title" />
      <div v-show="isOpen" class="ml-auto mr-xs md:mr-md">
        <FaIcon icon-class="fal fa-angle-up" classes="mx-auto" />
      </div>
      <div v-show="!isOpen" class="ml-auto mr-xs md:mr-md">
        <FaIcon icon-class="fal fa-angle-down" classes="mx-auto" />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="overflow-hidden bg-white text-vdv-font max-h-[100vh]"
    >
      <div class="p-sm md:px-md">
        <slot name="content" />
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
const props = defineProps({
  componentId: {
    type: String,
    required: false,
    default: null,
  },
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  // Styling
  classes: {
    type: String,
    required: false,
    default: '',
  },
  activeBarColor: {
    type: String,
    required: false,
    default: 'bg-activesection-base',
  },
  activeBarTextColor: {
    type: String,
    required: false,
    default: 'text-vdv-font',
  },
  barColor: {
    type: String,
    required: false,
    default: 'bg-vdv-bg-base',
  },
  barTextColor: {
    type: String,
    required: false,
    default: 'text-vdv-font',
  },
  // TODO: Following Props are not implemented yet
  contentColor: {
    type: String,
    required: false,
    default: '',
  },
  contentTextColor: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits<{
  (e: 'close', id: string): string;
  (e: 'open', id: string): string;
}>();

function toggleVisibility(): void {
  if (!props.isOpen) {
    emit('open', props.componentId);
  } else {
    emit('close', props.componentId);
  }
}

function getActiveBarClasses(): string {
  if (props.isOpen) {
    return `${props.activeBarColor} ${props.activeBarTextColor} font-bold`;
  } else {
    return `${props.barColor} ${props.barTextColor}`;
  }
}
</script>

<style scoped lang="postcss">
.vdv-accordion-single__content-wrapper > :slotted(*) {
  @apply px-sm;
  @apply pb-2xs;
  @apply pt-2xs;
}
</style>
