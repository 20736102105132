<template>
  <div :class="classes">
    <AccordionSingle
      v-for="accordion in state.accordionItems"
      :key="accordion.componentId"
      :component-id="accordion.componentId"
      :classes="accordion.classes"
      :active-bar-color="accordion.activeBarColor"
      :active-bar-text-color="accordion.activeBarTextColor"
      :bar-color="accordion.barColor"
      :bar-text-color="accordion.barTextColor"
      :is-open="accordion.isOpen"
      @open="accordionOpenHandler($event, $el)"
      @close="accordionCloseHandler"
    >
      <template #title>
        <p class="ml-[5px] md:ml-[20px]">
          {{ accordion.titleSlot }}
        </p>
      </template>
      <template #content>
        <div class="vdv-accordion-multi__content-slot">
          <WYSIWYGContentRaw
            v-if="props.contentType === EAccordionContentType.WYSIWYG"
            :content="accordion.contentSlot"
            no-container-padding
          />
          <Wysiwyg
            v-if="props.contentType === EAccordionContentType.TEMPLATE"
            :content="accordion.contentSlot"
            no-container-padding
          />
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="props.contentType === EAccordionContentType.TEXT"
            v-html="accordion.contentSlot"
          ></div>
        </div>
      </template>
    </AccordionSingle>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { EAccordionContentType, type IAccordionItem } from '@/@types/accordion';
import { scrollToElement } from '@/helpers/scroll';
import AccordionSingle from '@/components/components/core/accordion/vdv/accordion-single.vue';
import { WYSIWYGContentRaw } from '~/complib';
import Wysiwyg from '~/components/components/cms/wysiwyg/wysiwyg.vue';

const props = defineProps({
  accordionItems: {
    type: Object as PropType<IAccordionItem[]>,
    required: true,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  closeAll: {
    type: Boolean,
    required: false,
    default: null,
  },
  contentType: {
    type: String,
    required: false,
    default: EAccordionContentType.TEMPLATE,
  },
});

const state: AccordionMultiState = reactive({
  accordionItems: getAccordionItems(),
});

function getAccordionItems(): IAccordionItem[] {
  return props.accordionItems.map((accordionItem) => {
    accordionItem.componentId = uuidv4() as string;
    accordionItem.isOpen = false;
    return accordionItem;
  });
}

function accordionOpenHandler(accordionId: string, el: Element): void {
  scrollToElement(el, 200);
  accordionOpen(accordionId);
}

function accordionCloseHandler(accordionId: string): void {
  accordionClose(accordionId);
}

// Extend these functions and introduce a settings-prop if you need more control over when accordions auto-close
function accordionOpen(accordionId: string): void {
  state.accordionItems.map((accordion) => {
    accordion.isOpen = accordion.componentId === accordionId;
    return accordion;
  });
}

function accordionClose(accordionId: string): void {
  state.accordionItems.find((x) => x.componentId === accordionId).isOpen =
    false;
}

// Type Definitions
type AccordionMultiState = {
  accordionItems: IAccordionItem[];
};
</script>

<style scoped></style>
